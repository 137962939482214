import {
  Avatar,
  Breadcrumb,
  Button,
  ConfigProvider,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Popover,
  Result,
  Select,
  theme,
  Dropdown,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import changeCompanyIcon from "../../assets/img/changeCompanyIcon.svg";
import collapsedIcon from "../../assets/img/collapsedIcon.svg";
import collapsedLogo from "../../assets/img/collapsedLogo.svg";
import collapsedLogoDark from "../../assets/img/collapsedLogoDark.svg";
import darkModeIcon from "../../assets/img/darkModeIcon.svg";
import dashboardActiveIcon from "../../assets/img/dashboardActiveIcon.svg";
import dashboardIcon from "../../assets/img/dashboardIcon.svg";
import integrationsActiveIcon from "../../assets/img/integrationsActiveIcon.svg";
import integrationsIcon from "../../assets/img/integrationsIcon.svg";
import lightModeIcon from "../../assets/img/lightModeIcon.svg";
import membersActiveIcon from "../../assets/img/membersActiveIcon.svg";
import membersIcon from "../../assets/img/membersIcon.svg";
import projectsActiveIcon from "../../assets/img/projectsActiveIcon.svg";
import projectsIcon from "../../assets/img/projectsIcon.svg";
import rightIcon from "../../assets/img/rightIcon.svg";
import searchIcon from "../../assets/img/searchIcon.svg";
import settingsActiveIcon from "../../assets/img/settingsActiveIcon.svg";
import settingsIcon from "../../assets/img/settingsIcon.svg";
import unCollapsedIcon from "../../assets/img/unCollapsedIcon.svg";
import unCollapsedLogo from "../../assets/img/unCollapsedLogo.svg";
import unCollapsedLogoDark from "../../assets/img/unCollapsedLogoDark.svg";
import actionIcon from "../../assets/img/actionIcon.svg";
import orgIcon from "../../assets/img/orgIcon.svg";
import { useTheme } from "./ThemeContext";
import { actionLogout } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  actionAddOrganization,
  actionGetOrganization,
} from "../../store/services/orgService";
import { changeOrg } from "../../store/slices/authSlice";
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Root = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("collapsed");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [key, setKey] = useState("1");
  const [userData, setUserData] = useState([]);
  const [isAddORDModalOpen, setIsAddORDModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);
  const [showCompanyPopOver, setShowCompanyPopover] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef("");
  const location = useLocation();
  const { isDarkMode, setIsDarkMode } = useTheme();
  const [addORGForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const { organizations, addOrganizationLoader } = useSelector(
    (state) => state.organization
  );
  const { token, orgId } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  // const [selectedOrgId, setSelectedOrgId] = useState(null);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserData(decodedToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(actionGetOrganization());
    }
  }, [token]);

  useEffect(() => {
    if (!organizations?.length) return;

    setData(organizations);

    const validOrg = organizations.find((org) => org.id === orgId);

    const selectedOrgId = validOrg ? orgId : organizations[0]?.id;

    if (selectedOrgId !== orgId) {
      dispatch(changeOrg(selectedOrgId));
    }
  }, [organizations, orgId, dispatch]);

  useMemo(() => {}, [organizations]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm); // Set the search term state
    const filtered =
      data &&
      data.length > 0 &&
      data.filter((org) => org.orgName.toLowerCase().includes(searchTerm));
    setData(filtered);

    if (searchTerm.trim() === "") {
      setData(organizations);
    }
  };

  useMemo(() => {
    const routeKeyMapping = {
      "": "1",
      "main-dashboard": "1",
      project: "2",
      "create-project": "2",
      "project-detail": "2",
      "add-service": "2",
      "supply-chain": "3",
      "end-of-life": "4",
    };
    const pathParts = currentPath && currentPath.split("/");
    const pathKey = routeKeyMapping[pathParts[1]] || "1";
    setKey(pathKey);
  }, [currentPath]);

  const itemData = [
    {
      label: "Dashboard",
      icon: key === "1" ? dashboardActiveIcon : dashboardIcon,
      key: "1",
      link: "/",
    },
    {
      label: "Projects",
      icon: key === "2" ? projectsActiveIcon : projectsIcon,
      key: "2",
      link: "/project",
    },
    {
      label: "Supply chain",
      icon: key === "3" ? integrationsActiveIcon : integrationsIcon,
      key: "3",
      link: "/supply-chain",
    },
    {
      label: "End Of Life",
      icon: key === "4" ? membersActiveIcon : membersIcon,
      key: "4",
      link: "/end-of-life",
    },
    // {
    //   label: "Settings",
    //   icon: key === "5" ? settingsActiveIcon : settingsIcon,
    //   key: "5",
    //   link: "/settings",
    // },
  ];

  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  const items = [];
  itemData &&
    itemData.length > 0 &&
    itemData.forEach((data) => {
      const isArr = Array.isArray(data.link);

      const item = getItem(
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "sidebar-active" : ""
          }
          to={isArr ? data && data.link.map((l) => l) : data.link}
        >
          {data.label}
        </NavLink>,
        data.key,
        <img src={data.icon} alt={data.label} />
      );
      items.push(item);
    });

  const logo = isDarkMode
    ? collapsed
      ? collapsedLogoDark
      : unCollapsedLogoDark
    : collapsed
    ? collapsedLogo
    : unCollapsedLogo;

  // Get the label of the active menu item
  const currentMenuItem =
    itemData.find((item) => item.key === key)?.label || " ";

  const showAddORGModal = () => {
    setIsAddORDModalOpen(true);
    setShowCompanyPopover(false);
  };

  // close add org modal
  const handleCancelAddORGModal = () => {
    setIsAddORDModalOpen(false);
    setSelectedItems([]);
    addORGForm.resetFields();
  };

  const handleChangeTags = (value) => {
    setSelectedItems(value);
  };

  const handleOrgClick = (id) => {
    dispatch(changeOrg(id));
    setShowCompanyPopover(false);
    setData(organizations);
  };

  // Function to handle popover toggle and clear input
  const handlePopoverToggle = () => {
    setShowCompanyPopover(!showCompanyPopOver);

    // Clear input and reset data if the popover is closing
    if (showCompanyPopOver) {
      setSearchTerm(""); // Clear the search term state
      inputRef.current = ""; // Clear input field
      setData(organizations); // Reset data
    }
  };

  // select company model
  const selectCompanyModal = (
    <>
      <div className="min-w-[350px] z-10">
        <Input
          onChange={handleSearch}
          placeholder="Search"
          className="py-2 border border-primary bg-transparent hover:bg-transparent text-text-primary"
          prefix={<img src={searchIcon} alt="icon" className="mr-2" />}
        />
        <div className="gap-y-2 mt-2 custom-search">
          <div className="max-h-[250px] custom-scrollbar">
            {data && data.length > 0 ? (
              data.map((org) => (
                <div
                  key={org.id}
                  className="h-[50px] flex gap-x-3 items-center relative cursor-pointer"
                  onClick={() => handleOrgClick(org.id)}
                >
                  <Avatar
                    size="large"
                    style={{
                      backgroundColor: "#fde3cf",
                      color: "#f56a00",
                    }}
                  >
                    {org.orgName
                      ? org.orgName.slice(0, 2).toUpperCase()
                      : "N/A"}
                  </Avatar>
                  <p className="text-[#888] text-[16px] font-montserratMedium">
                    {org?.orgName}
                  </p>
                  {orgId === org.id && (
                    <span className="absolute right-2">
                      <img src={rightIcon} alt="icon" />
                    </span>
                  )}
                </div>
              ))
            ) : (
              <p className="text-[#A9B5C4] text-[26px] font-montserratMedium text-center">
                No Data
              </p>
            )}
          </div>
          {/*   <Select
            showSearch
            allowClear
            placeholder="Select a organization"
            filterOption={(input, option) =>
              (option?.label.props.children[1]?.props.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            className="w-full "
            options={organizationData}
            getPopupContainer={(triggerNode) => {
              // Replace this selector with the div where you want the dropdown to appear
              return document.querySelector(".custom-search");
            }}
          /> */}
          <div className="border-b border-b-border pt-2"></div>
          <div className="mt-3 text-end">
            <button
              onClick={showAddORGModal}
              className="text-[#707070] border border-border rounded px-3 py-2 text-[13px] font-montserratMedium"
            >
              + Create New Organization
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );

  const showLogoutModal = () => {
    setVisibleLogoutModal(true);
  };

  const handleLogout = () => {
    setVisibleLogoutModal(false);
  };

  const handleCancelLogout = () => {
    setVisibleLogoutModal(false);
  };

  //  profile itmes
  const menuItems = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center text-[16px] font-montserratMedium"
          onClick={showLogoutModal}
        >
          <LogoutOutlined className="pr-2.5" />
          Logout
        </div>
      ),
    },
    // {
    //   key: "1",
    //   label: <p>Edit Profile</p>,
    //   icon: <EditOutlined />,
    //   onClick: () =>
    //     navigate("/update-user", {
    //       state: {
    //         userId: currentCompanyUser?.id,
    //         accountType: "OWN_ACCOUNT",
    //       },
    //     }),
    // },
  ];

  const handleAddORG = async (value) => {
    const req = {
      ...value,
      tagList: selectedItems,
    };
    try {
      const result = await dispatch(
        actionAddOrganization({
          values: req,
          handleCancel: handleCancelAddORGModal,
        })
      );
      const newOrgId = result?.payload; // Extract new organization ID
      if (newOrgId) {
        await dispatch(actionGetOrganization());
        dispatch(changeOrg(newOrgId));
      }
    } catch (error) {
      console.error("Error adding organization:", error);
    }
  };

  const name =
    organizations &&
    organizations.length > 0 &&
    organizations.find((org) => org.id === orgId);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            colorBgBase: isDarkMode ? "#111111" : "#ffffff",
            colorText: isDarkMode ? "#ffffff" : "#000000",
            algorithm: true,
            borderColor: isDarkMode ? "#081525" : "#AEBBCC", // Set the border color like text color
            headerBg: isDarkMode ? "#2B2B2B" : "#F2F2F2",
            headerColor: isDarkMode ? "#ffffff" : "#000000",
            fontFamily: "Montserrat-Medium",
            rowSelectedBg:"transparent",
            rowSelectedHoverBg:"transparent"
          },
          Tooltip: {
            colorBgSpotlight: isDarkMode ? "#ffffff" : "#000000",
            colorTextLightSolid: isDarkMode ? "#000000" : "#fff",
          },
          Tabs: {
            colorText: isDarkMode ? "#fff" : "#000",
            itemActiveColor: "#fff",
            colorBgContainer: "#1778F2",
            itemSelectedColor: "#fff",
            cardBg: "transparent",
            colorBorderSecondary: "transparent",
            itemHoverColor: "#1778F2",
            fontFamily: "Montserrat-Medium",
          },
          Switch: {
            colorTextQuaternary: "#8A8A8A",
            colorTextTertiary: "#939292",
          },
          Select: {
            // colorIcon:"red"
            optionSelectedColor:"#1778f2"
          },
          Button: {
            colorBgContainerDisabled:isDarkMode ? "#262626" : "#E4E4E4",
            colorTextDisabled:isDarkMode ? "#595959" : "#A7A7A7",
            borderColor:isDarkMode ? "#262626" : "#E4E4E4",
            lineWidth:0
          }
        },
      }}
    >
      <Layout className="max-h-screen overflow-hidden">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={250}
          breakpoint="xxl"
          onCollapse={(c) => {
            setCollapsed(collapsed);
          }}
          className="side-menu-light duration-1000 max-h-screen fixed overflow-auto ease-in relative"
          style={{
            height: "100vh",
          }}
        >
          <div
            className={`pl-2 mx-auto flex justify-between ${
              collapsed ? "px-[8px]" : "px-[0px]"
            }`}
          >
            <div
              className={`flex items-center ${
                collapsed ? "justify-center" : ""
              }`}
            >
              <img
                alt="Logo"
                src={logo}
                style={{
                  padding: collapsed ? "" : 4,
                  width: collapsed ? 64 : "auto",
                  height: collapsed ? 64 : "auto",
                }}
              />
            </div>
            <Button
              type="text"
              icon={collapsed ? null : <img src={collapsedIcon} alt="icon" />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                display: collapsed ? "none" : "flex", // Conditionally apply display
                width: 40,
                height: 64,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </div>
          <div
            className={`flex items-center  px-2 ${
              collapsed ? "justify-center" : "justify-between"
            }`}
          >
            <div className="h-[50px] flex gap-x-2 items-center">
              {collapsed ? (
                <Popover
                  placement="rightTop"
                  trigger="click"
                  open={showCompanyPopOver}
                  content={selectCompanyModal}
                  onOpenChange={handlePopoverToggle}
                >
                  <Avatar
                    onClick={handlePopoverToggle}
                    className="cursor-pointer"
                    size="large"
                    style={{
                      backgroundColor: "#fde3cf",
                      color: "#f56a00",
                    }}
                  >
                    {name && name.orgName
                      ? name?.orgName.slice(0, 2).toUpperCase()
                      : "N/A"}
                  </Avatar>
                </Popover>
              ) : (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#f56a00",
                  }}
                >
                  {name && name.orgName
                    ? name.orgName.slice(0, 2).toUpperCase()
                    : "N/A"}
                </Avatar>
              )}
              {!collapsed && (
                <p className="text-[#A9B5C4] text-[16px] font-montserratMedium">
                  {name && name.orgName}
                </p>
              )}
            </div>
            {!collapsed && (
              <Popover
                destroyTooltipOnHide
                zIndex={0}
                open={showCompanyPopOver}
                placement="rightTop"
                trigger="click"
                content={selectCompanyModal}
                onOpenChange={handlePopoverToggle}
              >
                <div onClick={handlePopoverToggle} className="cursor-pointer">
                  <img src={changeCompanyIcon} alt="icon" />
                </div>
              </Popover>
            )}
          </div>
          <Menu mode="inline" selectedKeys={[key]} items={items} />
          <div
            className={`${
              collapsed ? "justify-center" : " "
            } absolute bottom-4 px-3 flex items-center w-full gap-x-3`}
          >
            <div className="flex items-center gap-x-2">
              {collapsed ? (
                <Dropdown
                  className="cursor-pointer flex items-center justify-center"
                  placement="left"
                  menu={{
                    items: menuItems,
                  }}
                  trigger={"click"}
                >
                  <Avatar
                    style={{
                      backgroundColor: isDarkMode ? "#DBECFF" : "#0062F4",
                      color: isDarkMode ? "#0062F4" : "#fff",
                      fontWeight: 500,
                    }}
                    size={40}
                  >
                    {userData &&
                      userData.sub &&
                      userData.sub.charAt(0).toUpperCase()}
                  </Avatar>
                </Dropdown>
              ) : (
                <Avatar
                  style={{
                    backgroundColor: isDarkMode ? "#DBECFF" : "#0062F4",
                    color: isDarkMode ? "#0062F4" : "#fff",
                    fontWeight: 500,
                  }}
                  size={40}
                >
                  {userData &&
                    userData.sub &&
                    userData.sub.charAt(0).toUpperCase()}
                </Avatar>
              )}

              <p
                className={`${
                  collapsed ? "hidden" : "block"
                } text-[16px] text-[#AAB5C4] font-montserratMedium`}
              >
                {userData && userData.sub}
              </p>
            </div>
            <Dropdown
              className="cursor-pointer flex items-center justify-center"
              placement="top"
              arrow={{
                pointAtCenter: true,
              }}
              menu={{
                items: menuItems,
              }}
              trigger={"click"}
            >
              <div className={`${collapsed ? "hidden" : "block"}`}>
                <img src={actionIcon} alt="icon" />
              </div>
            </Dropdown>
          </div>
        </Sider>
        <Layout className="max-h-screen bg-background">
          <Header
            className="p-0 flex items-center bg-background border-b border-b-border"
            style={{
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="flex justify-between items-center w-full">
              <div
                className={`${
                  collapsed ? "flex" : ""
                } justify-center items-center gap-x-4`}
              >
                <Button
                  type="text"
                  icon={
                    collapsed ? <img src={unCollapsedIcon} alt="icon" /> : null
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    display: !collapsed ? "none" : "flex",
                    border: "1px solid #DEDEDE",
                    marginLeft: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                {name && (
                  <Breadcrumb
                    separator=">"
                    className={`${collapsed ? "ml-0" : "ml-4"}`}
                  >
                    <Breadcrumb.Item className="text-text-primary text-[16px] font-montserratRegular">
                      {name && name.orgName}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="text-text-primary text-[16px] font-montserratRegular">
                      {currentMenuItem}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                )}
              </div>
              <div className="flex border border-border rounded p-1 mr-3 gap-x-1">
                <div
                  className={`w-[20px] h-[20px] md:w-[25px] md:h-[25px] rounded flex justify-center items-center cursor-pointer transition duration-500 ${
                    isDarkMode ? "bg-transparent" : "bg-primary"
                  }`}
                  onClick={() => setIsDarkMode(false)}
                >
                  <img src={lightModeIcon} alt="light" />
                </div>
                <div
                  className={`w-[20px] h-[20px] md:w-[25px] md:h-[25px] rounded flex justify-center items-center cursor-pointer transition duration-500 ${
                    isDarkMode ? "bg-white" : "bg-transparent"
                  }`}
                  onClick={() => setIsDarkMode(true)}
                >
                  <img src={darkModeIcon} alt="dark" />
                </div>
              </div>
            </div>
          </Header>
          <Content className="p-[18px] overflow-y-auto  min-h-[280px]">
            <Outlet />
          </Content>
          <Modal
            centered
            destroyOnClose={() => addORGForm.resetFields()}
            onOk={() => handleCancelAddORGModal()}
            onCancel={() => handleCancelAddORGModal()}
            footer={false}
            width={500}
            open={isAddORDModalOpen}
          >
            <h1 className="text-text-primary text-[25px] font-montserratRegular text-center">
              Add new organization
            </h1>
            <Form
              form={addORGForm}
              onFinish={handleAddORG}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="orgName"
                label="Name"
                className="mb-[12px]"
                size="medium"
                rules={[
                  {
                    required: true,
                    message: "Please input org name!",
                  },
                ]}
              >
                <Input
                  prefix={<img src={orgIcon} alt="icon" className="mr-2" />}
                  style={{ background: "white" }}
                  placeholder="Enter Organization Name"
                  className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
                />
              </Form.Item>

              <Form.Item
                label="Tags"
                name="tagList"
                rules={[
                  {
                    required: false,
                    message: "Please input tags!",
                  },
                ]}
              >
                <div className="select-with-prefix">
                  <Select
                    open={true}
                    className="remove-arrow"
                    popupClassName="tag-select"
                    multiple={true}
                    showSearch={false}
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Enter tags"
                    value={selectedItems}
                    onChange={handleChangeTags}
                    notFoundContent={null}
                  />
                </div>
              </Form.Item>

              <div className="flex justify-end mt-[40px]">
                <Form.Item className="mb-0">
                  <button
                    onClick={() => handleCancelAddORGModal()}
                    type="button"
                    className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
                  >
                    Cancel
                  </button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addOrganizationLoader}
                      type="primary"
                      className="min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                      htmlType="submit"
                    >
                      Create organization
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            open={visibleLogoutModal}
            onOk={handleLogout}
            onCancel={handleCancelLogout}
            footer={null}
          >
            <Result
              className="!text-text-secondary"
              status="warning"
              title="Are you sure you want to logout?"
              extra={[
                <button
                  key="cancel"
                  onClick={handleCancelLogout}
                  className="rounded-[6px]  text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                >
                  Cancel
                </button>,
                <Button
                  type="primary"
                  className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                  key="logout"
                  onClick={() => {
                    dispatch(actionLogout({ navigate, setVisibleLogoutModal }));
                  }}
                >
                  Logout
                </Button>,
              ]}
            />
          </Modal>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Root;
